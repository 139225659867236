<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName === "evaluating-weight-create"
                ? "Add Weight"
                : "Edit Weight"
            }}
          </h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card" v-if="!loading">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row mb-3">
                <label for="level" class="col-md-3 form-label">
                  Program
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'evaluating-weight-edit',
                      'evaluating-weight-create',
                    ])
                  "
                >
                  <v-select
                    @option:selected="getSelectedProgram"
                    v-model="selectedProgram"
                    label="name"
                    :options="programs"
                    :clearable="false"
                    :selectable="(options) => isSelectableProgram(options)"
                    :class="{
                      'p-invalid':
                        v$.selectedProgram.$error || errorFor('name'),
                    }"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('name')"
                    :vuelidateErrors="{
                      errors: v$.selectedProgram.$errors,
                      value: 'Program Name',
                    }"
                  ></v-errors>
                </div>
                <div
                  class="col-md-6"
                  v-else-if="hasPermissions(['evaluating-weight-list'])"
                >
                  <v-select
                    v-model="selectedProgram"
                    label="name"
                    :clearable="false"
                    disabled
                  >
                  </v-select>
                </div>
              </div>

              <div class="row mb-3">
                <label for="type" class="col-md-3 form-label">
                  Type
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'evaluating-weight-edit',
                      'evaluating-weight-create',
                    ])
                  "
                >
                  <v-select
                    @option:selected="getSelectedType($event)"
                    v-model="weight.type"
                    label="name"
                    :options="types"
                    :selectable="(options) => isSelectableType(options)"
                    :reduce="(name) => name.name"
                    :clearable="false"
                    :class="{
                      'p-invalid': v$.weight.type.$error || errorFor('type'),
                    }"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('type')"
                    :vuelidateErrors="{
                      errors: v$.weight.type.$errors,
                      value: 'Level',
                    }"
                  ></v-errors>
                </div>
                <div
                  class="col-md-6"
                  v-else-if="hasPermissions(['evaluating-weight-list'])"
                >
                  <v-select
                    disabled
                    v-model="weight.type"
                    label="name"
                    :clearable="false"
                  >
                  </v-select>
                </div>
              </div>

              <div
                v-if="weight.type == 'In-Class' || weight.type == 'OJT'"
                class="row mb-3"
              >
                <label for="level" class="col-md-3 form-label">
                  Level
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6">
                  <v-select
                    @option:selected="getSelectedLevel($event)"
                    v-model="weight.level"
                    label="name"
                    :options="levels"
                    :selectable="(options) => isSelectableLevel(options)"
                    :reduce="(name) => name.name"
                    :clearable="false"
                    :class="{
                      'p-invalid': v$.weight.level.$error || errorFor('level'),
                    }"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('level')"
                    :vuelidateErrors="{
                      errors: v$.weight.level.$errors,
                      value: 'Level',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <label for="type" class="col-md-3 form-label">
                  Criteria
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'evaluating-weight-edit',
                      'evaluating-weight-create',
                    ])
                  "
                >
                  <v-select
                    v-model="weight.evaluating_criteria_id"
                    label="name"
                    :options="criterias"
                    :selectable="(criterias) => criterias.alreadyCreated"
                    :reduce="(name) => name.id"
                    :clearable="false"
                    :class="{
                      'p-invalid':
                        v$.weight.evaluating_criteria_id.$error ||
                        errorFor('evaluating_criteria_id'),
                    }"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('evaluating_criteria_id')"
                    :vuelidateErrors="{
                      errors: v$.weight.evaluating_criteria_id.$errors,
                      value: 'Criteria',
                    }"
                  ></v-errors>
                </div>
                <div
                  class="col-md-6"
                  v-else-if="hasPermissions(['evaluating-weight-list'])"
                >
                  <v-select
                    v-model="weight.evaluating_criteria_id"
                    disabled
                    label="name"
                    :clearable="false"
                  >
                  </v-select>
                </div>
              </div>

              <div class="row mb-3">
                <label for="weight" class="col-md-3 form-label">
                  Weight
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-4"
                  v-if="
                    hasPermissions([
                      'evaluating-weight-edit',
                      'evaluating-weight-create',
                    ])
                  "
                >
                  <input
                    type="number"
                    id="weight"
                    name="weight"
                    class="form-control"
                    oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                    placeholder="Weight"
                    v-model="weight.weight"
                    :class="{
                      'p-invalid':
                        v$.weight.weight.$error || errorFor('weight'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('weight')"
                    :vuelidateErrors="{
                      errors: v$.weight.weight.$errors,
                      value: 'Weight',
                    }"
                  ></v-errors>
                </div>
                <div
                  class="col-md-4"
                  v-else-if="hasPermissions(['evaluating-weight-list'])"
                >
                  <input
                    type="number"
                    id="weight"
                    name="weight"
                    class="form-control"
                    v-model="weight.weight"
                    disabled
                  />
                </div>
                <div class="col-md-2 ms-2 mt-1">
                  <span>Precent</span>
                </div>
              </div>
              <!-- end row -->
              <div
                class="row"
                v-if="
                  hasPermissions([
                    'evaluating-weight-edit',
                    'evaluating-weight-create',
                  ])
                "
              >
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'evaluating-weight' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'evaluating-weight-update'
                          ? updateWeight()
                          : createCriteria()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      programs: [],
      levels: [],
      types: [
        {
          id: 1,
          name: "Orientation",
        },
        {
          id: 2,
          name: "In-Class",
        },
        {
          id: 3,
          name: "OJT",
        },
      ],
      weight: {
        program_id: "",
        program_name: "",
        program_short_name: "",
        type: "",
        level: "",
        evaluating_criteria_id: "",
        weight: "",
      },
      selectedProgramCriterias: [],
      selectedTypeCriterias: [],
      criterias: [],
      selectedProgram: null,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      weight: {
        program_id: { required },
        program_name: { required },
        program_short_name: { required },
        type: { required },
        level: {
          required: requiredIf(() => {
            return this.weight.type != "Orientation";
          }),
        },
        evaluating_criteria_id: { required },
        weight: { required },
      },
    };
  },
  methods: {
    isSelectableProgram(options) {
      if (!this.selectedProgram || !options) return true;
      return this.selectedProgram.id != options.id;
    },
    isSelectableType(options) {
      if (!this.weight.type || !options) return true;
      return this.weight.type != options.name;
    },
    isSelectableLevel(options) {
      if (!this.weight.level || !options) return true;
      return this.weight.level != options.name;
    },
    getSelectedProgram() {
      this.criterias = [];
      this.weight.type = "";
      this.weight.level = "";
      this.weight.evaluating_criteria_id = "";
      if (this.selectedProgram) {
        this.weight.program_id = this.selectedProgram.id;
        this.weight.program_name = this.selectedProgram.name;
        this.weight.program_short_name = this.selectedProgram.short_name;
        this.getSelectedProgramId(this.selectedProgram.id);
      }
    },

    async getSelectedProgramId(id) {
      // this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v2/program-evaluating-criteria/${id}`)
        .then((response) => {
          if (response.data.data != null) {
            this.selectedProgramCriterias = response.data.data.filter(function (
              val
            ) {
              if (val.status == 1) {
                return {
                  id: val.id,
                  level: val.level,
                  name: val.name,
                  status: val.status,
                  type: val.type,
                };
              }
            });
          }
          if (this.currentRouteName == "evaluating-weight-update") {
            this.getSelectedType(this.weight.type);
          }
          // this.loading = false;
        });
    },

    getSelectedType(type) {
      // this.weight.level = "";
      if (type != this.weight.type) this.weight.evaluating_criteria_id = "";
      if (this.selectedProgram) {
        if (this.weight.type != "Orientation") {
          this.selectedTypeCriterias = this.selectedProgramCriterias.filter(
            function (item) {
              if (item.type == type.name || item.type == type) {
                return item;
              }
            }
          );
        } else {
          this.criterias = this.selectedProgramCriterias.filter(function (
            item
          ) {
            if (item.type == type.name || item.type == type) {
              return item;
            }
          });
        }
      }
    },

    getSelectedLevel(level) {
      this.weight.evaluating_criteria_id = "";
      if (this.selectedProgram) {
        this.criterias = this.selectedTypeCriterias.filter(function (item) {
          if (item.level == level.name) {
            return item;
          }
        });
      }
    },

    getPrograms() {
      axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getAllLevels() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
        this.levels = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    async getCriteriaById() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/evaluating-weight/${this.$route.params.id}`
        )
        .then((response) => {
          const result = response.data.data;
          this.weight = result;
          this.selectedProgram = {
            id: result.program_id,
            name: result.program_name,
            short_name: result.program_short_name,
          };

          this.criterias.push(result.evaluating_criteria);
          this.weight.type = result.evaluating_criteria.type;
          this.weight.level = result.evaluating_criteria.level;
          this.weight.evaluating_criteria_id = result.evaluating_criteria_id;
          this.weight.weight = result.weight;
          this.getSelectedProgramId(this.selectedProgram.id);

          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Criteria!");
        });
    },

    // async getAllEvaluatingCriteria() {
    //     this.loading = true;
    //     this.$Progress.start();
    //     await axios.post(`${this.baseUrl}admin/v2/get-evaluating-criteria`).then((response) => {
    //         // this.criterias = response.data.data;
    //         this.criterias = response.data.data.filter(function (val) {
    //             //for active Criteria
    //             if (val.status == 1) {
    //             return {
    //                 id: val.id,
    //                 level: val.level,
    //                 name: val.name,
    //                 status: val.status,
    //                 type: val.type,
    //             };
    //             }
    //         });
    //         this.loading = false;
    //         this.$Progress.finish();
    //     });
    // },

    async createCriteria() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;

      await axios
        .post(`${this.baseUrl}admin/v2/evaluating-weight`, this.weight)
        .then(() => {
          this.$router.push({ name: "evaluating-weight" });
          this.toast.success("Successfully Created Criteria!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async updateWeight() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      let data = {
        program_id: this.weight.program_id,
        program_name: this.weight.program_name,
        program_short_name: this.weight.program_short_name,
        type: this.weight.type,
        level: this.weight.level,
        evaluating_criteria_id: this.weight.evaluating_criteria_id,
        weight: this.weight.weight,
      };
      this.isLoading = true;
      axios
        .put(
          `${this.baseUrl}admin/v2/evaluating-weight/${this.$route.params.id}`,
          data
        )
        .then(() => {
          this.$router.push({ name: "evaluating-weight" });
          this.toast.success("Successfully Criteria Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getPrograms();
    this.getAllLevels();
    // this.getAllEvaluatingCriteria();
    if (this.currentRouteName == "evaluating-weight-update") {
      this.getCriteriaById();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>
